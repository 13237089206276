<!--交付评价信息-->
<template>
    <div class="deliveryEvaluationInfo">
        <div class="deliver-wrapper">
            <h2 class="title">
                交付评价信息
            </h2>
            <h3 class="title2">
                <i class="iconfont el-icon-picture"></i>
                图片资料
            </h3>
            <div class="imgList">
                <el-image
                    v-for="(imgUrl,index) in imgList"
                    :key="index"
                    :src="imgUrl"
                    :preview-src-list="imgList"
                >
                </el-image>
                <el-image v-if="imgList.length===0">
                    <div slot="error" class="image-null">
                        暂无图片
                    </div>
                </el-image>
            </div>
        </div>
        <div class="deliver-wrapper rate-wrapper">
            <h3 class="title2">
                <i class="iconfont iconqiepian"></i>
                评价信息
            </h3>
            <div class="rate-list">
                <div class="rate-item" v-for="item in (rateInfo.evaluateDetails||[])" :key="item.option_name">
                    <label>{{ item.option_name }}</label>
                    <el-rate
                        disabled
                        v-model="item.score"
                        show-text
                        text-color="#979EA7"
                        score-template="{value}"
                        :texts="rateTexts"
                    ></el-rate>
                </div>
            </div>
        </div>
        <div class="deliver-wrapper">
            <h3 class="title2">
                <i class="iconfont iconxiugai"></i>
                备注信息
            </h3>
            <div class="remark-text">
                {{ rateInfo.driver_remark }}
            </div>
        </div>
        <div class="btn-wrapper">
            <div class="close-btn" @click="handleClose">
                关闭
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'delivery-evaluation-info',
    data() {
        return {
            imgList: [],

            rateTexts: ['差', '差', '一般', '好', '非常好'],
            // 评价基本信息
            rateInfo: {},
        };
    },
    props: {
        extr: Object,
    },
    created() {
        this.getInfo();
    },
    methods: {
        getInfo() {
            this.$axios.get('/interfaceApi/production/productpcb/get_driver_evaluate?pcb_number=' + this.extr.pcb_number)
                .then(res => {
                    if (res) {
                        this.rateInfo = res;
                        if (res.driver_files) {
                            const fileStr = res.driver_files.split(',');
                            fileStr.map(item => {
                                if (item) {
                                    // const url = this.FILE.FILE_BASE_URL() + 'file/' + item + '/0';
                                    // this.imgList.push(url);
                                    this.getFileDetailFun(item, 'imgList');
                                }
                            });
                        }
                    }
                });
        },
        getFileDetailFun(id, key) {
            this.$axios
                .get(this.FILE.FILE_BASE_URL_OSS() + 'api/GetFileInfo/' + id)
                .then(res => {
                    if (res.file_ext !== '') {
                        this[key].push(res.preview_url);
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 关闭
        handleClose() {
            this.$parent.hide();
        },
    },
};
</script>

<style lang="stylus" scoped>
.deliveryEvaluationInfo{
    width 100%;
    .deliver-wrapper{
        padding 0.1rem 0.45rem
        &.rate-wrapper{
            border-top 1px solid #F0F0F0
            border-bottom 1px solid #F0F0F0
        }
        .title{
            font-size 0.2rem;
            color #333;
        }
        .title2{
            margin-top 0.1rem;
            font-size 0.16rem
            color #979EA7
            .iconfont{
                font-size 0.16rem
                margin-right 0.05rem
                color #979EA7
            }
        }
        .imgList{
            width 100%;
            overflow-x auto
            margin-top 0.1rem
            padding-bottom 0.05rem;
            .el-image{
                width 1rem;
                height 1rem;
                border-radius: 2px;
                margin-right 0.2rem
                >>> .image-null {
                    width 100%;
                    height 100%;
                    background #f5f7fa
                    display flex
                    justify-content center
                    align-items center
                    color #c0c4cc;
                }
            }
        }
        .rate-list{
            .rate-item{
                width 100%;
                height 0.4rem
                display flex
                align-items center
                label{
                    display flex
                    width 2rem
                    font-size 0.16rem
                    color #333;
                }
                >>> .el-rate__icon{
                    font-size 0.24rem;
                 }
            }
        }
        .remark-text{
            padding 0.05rem
            font-size 0.16rem
            color #333;
            line-height 0.24rem;
        }
    }
    .btn-wrapper{
        display flex
        justify-content center
        padding 0.3rem
        .close-btn{
            width: 1.3rem;
            height: 0.36rem;
            line-height 0.36rem
            text-align center
            color #1577D2
            border-radius: 1px;
            border: 1px solid #1577D2;
            cursor pointer
        }
    }
}
</style>
